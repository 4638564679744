var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "herder"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('div', {
    staticClass: "head-l"
  }, [_vm._m(0), _c('div', {
    staticClass: "portrait-describe"
  }, [_c('p', {
    staticClass: "describe-title"
  }, [_vm._v(_vm._s(_vm.userInfo.userName))]), _c('p', {
    staticClass: "describe-time"
  }, [_vm._v(" 上次登陆："), _c('span', [_vm._v(_vm._s(_vm.userInfo.lastLoginTime))])])])]), _c('div', {
    staticClass: "head-c"
  }, [_c('div', {
    staticClass: "transfer-box"
  }, [_vm._m(1), _vm._m(2), _c('div', {
    staticClass: "transfer-box-content"
  }, [_c('div', {
    staticClass: "content-item"
  }, [_c('p', [_vm._v("单位名称:")]), _c('div', {
    staticClass: "contentText"
  }, [_c('span', [_vm._v(_vm._s(_vm.company))]), _c('span', [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.copyData(_vm.company);
      }
    }
  }, [_vm._v("复制")])])])]), _c('div', {
    staticClass: "content-item"
  }, [_c('p', [_vm._v("银行账号:")]), _c('div', {
    staticClass: "contentText"
  }, [_c('span', [_vm._v(_vm._s(_vm.account + " " + _vm.userInfo.account))]), _c('span', [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.copyData(_vm.accountNumber + _vm.userInfo.account);
      }
    }
  }, [_vm._v("复制")])])])]), _c('div', {
    staticClass: "content-item"
  }, [_c('p', [_vm._v("开户银行:")]), _c('div', {
    staticClass: "contentText"
  }, [_c('span', [_vm._v(_vm._s(_vm.monicker))]), _c('span', [_c('button', {
    on: {
      "click": function click($event) {
        return _vm.copyData(_vm.monicker);
      }
    }
  }, [_vm._v("复制")])])])])])])]), _c('div', {
    staticClass: "head-r"
  }, [_c('div', {
    staticClass: "head-geren"
  }, [_c('div', [_vm._v("我的账号")]), _c('div', {
    staticClass: "head-geren-c"
  }, [_vm._v(_vm._s(_vm.userInfo.account))])]), _vm._m(3), _c('div', {
    staticClass: "head-geren"
  }, [_c('div', [_vm._v("手机号")]), _c('div', {
    staticClass: "head-geren-cc"
  }, [_vm._v(_vm._s(_vm.phone || _vm.startPhone))]), _c('div', {
    staticClass: "head-geren-l"
  }, [_vm.isBindYourMobilePhoneNumber ? _c('button', {
    on: {
      "click": _vm.pinless
    }
  }, [_vm._v("绑定")]) : _c('button', {
    staticStyle: {
      "background": "#ff5757"
    },
    on: {
      "click": _vm.pinless
    }
  }, [_vm._v("待绑定")])])]), _vm._m(4), _vm._m(5), _vm._m(6)])]), _c('div', {
    staticClass: "dataList-new"
  }, [_c('div', {
    staticClass: "dataList-new-z"
  }, [_c('div', {
    staticClass: "dataList-new-z-head"
  }, [_c('div', {
    staticClass: "dataList-new-z-head-item1"
  }), _c('div', {
    staticClass: "dataList-new-z-head-item2"
  }, [_vm._v(_vm._s(_vm.currentYear) + "年月度让利")])]), _c('div', {
    staticClass: "dataList-new-z-content"
  }, [_c('div', {
    staticClass: "rlList"
  }, [_vm._m(7), _c('div', {
    staticClass: "rlList-list"
  }, _vm._l(_vm.summaryData, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "rlList-list-line"
    }, [_c('div', {
      staticClass: "rlItem months"
    }, [_vm._v(_vm._s(item.currentMonth))]), _c('div', {
      staticClass: "rlItem"
    }, [_vm._v("￥" + _vm._s(item.procureAmount))]), _c('div', {
      staticClass: "rlItem"
    }, [_vm._v("￥" + _vm._s(item.discountAmount))]), _c('div', {
      staticClass: "rlItem"
    }, [_vm._v("￥" + _vm._s(item.actualAmount))]), _c('div', {
      class: ['rlItem', index === 0 ? 'act' : '']
    }, [_vm._v(_vm._s(item.discountPercent) + "%")])]);
  }), 0)])])])]), _vm._m(8)]), _c('Dialog', {
    attrs: {
      "show": _vm.isShowSimilar,
      "width": "630px",
      "height": "528px"
    },
    on: {
      "close": function close($event) {
        _vm.isShowSimilar = false;
      }
    }
  }, [_c('div', {
    staticClass: "currentYear",
    on: {
      "click": function click($event) {
        _vm.showcard = true;
      }
    }
  }, [_vm._v(" 年度汇总 "), _c('div', [_c('b-select', {
    staticClass: "select",
    attrs: {
      "model": _vm.nfOptions,
      "placeholder": _vm.selectDate.year + ''
    },
    on: {
      "input": _vm.changeInvitationRole
    }
  }, _vm._l(_vm.nfOptions, function (option, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0)], 1)]), _c('div', {
    staticClass: "line"
  }), _c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v("月份")]), _c('th', [_vm._v("采购总额")]), _c('th', [_vm._v("让利总额")]), _c('th', [_vm._v("实付总额")]), _c('th', [_vm._v("让利比例")])])]), _c('tbody', _vm._l(_vm.summaryData, function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(item.currentMonth))]), _c('td', [_vm._v("￥" + _vm._s(item.procureAmount))]), _c('td', [_vm._v("￥" + _vm._s(item.discountAmount))]), _c('td', [_vm._v("￥" + _vm._s(item.actualAmount))]), _c('td', [_vm._v(_vm._s(item.discountPercent) + "%")])]);
  }), 0)])])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "headImg"
  }, [_c('img', {
    attrs: {
      "src": require("../images/gr_touxiang.png"),
      "alt": ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "transfer-top"
  }, [_c('img', {
    attrs: {
      "src": require("../images/gr_duigong.png"),
      "alt": ""
    }
  }), _c('span', [_vm._v("对公转账信息")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "transfer-top-des"
  }, [_c('span', [_vm._v("复制22位账号，对公付款 自动下账")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head-geren"
  }, [_c('div', [_vm._v("专属客服")]), _c('div', {
    staticClass: "head-geren-c"
  }, [_vm._v("陶秋萍")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head-geren"
  }, [_c('div', [_vm._v("客服电话")]), _c('div', {
    staticClass: "head-geren-ccc"
  }, [_vm._v("023-88609999")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head-geren"
  }, [_c('div', [_vm._v("我的资质")]), _c('div', {
    staticClass: "head-geren-ccccc"
  }, [_vm._v("已过审")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "head-geren"
  }, [_c('div', [_vm._v("业务员")]), _c('div', {
    staticClass: "head-geren-cccc"
  }, [_vm._v("陶秋萍")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "rlList-title"
  }, [_c('div', [_vm._v("月份")]), _c('div', [_vm._v("采购总额")]), _c('div', [_vm._v("让利总额")]), _c('div', [_vm._v("实付总额")]), _c('div', [_vm._v("让利比例")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "activity-description"
  }, [_c('div', {
    staticClass: "activity-description-head"
  }, [_c('div', {
    staticClass: "head-img"
  }), _c('div', {
    staticClass: "head-text"
  }, [_vm._v("让利类型")])]), _c('div', {
    staticClass: "activity-description-content"
  }, [_c('div', {
    staticClass: "activity-description-content-item"
  }, [_c('div', {
    staticClass: "item-s"
  }, [_vm._v(" 1.返活动红包 ")]), _c('div', {
    staticClass: "item-x"
  }, [_vm._v(" 选购“ "), _c('span', {
    staticClass: "h it1"
  }, [_vm._v("红包")]), _vm._v(" ”标签商品，满足活动数量，下单"), _c('span', {
    staticClass: "red"
  }, [_vm._v("立返红包")])])]), _c('div', {
    staticClass: "activity-description-content-item"
  }, [_c('div', {
    staticClass: "item-s"
  }, [_vm._v(" 2.满减活动 ")]), _c('div', {
    staticClass: "item-x"
  }, [_vm._v(" 选购“ "), _c('span', {
    staticClass: "h it2"
  }, [_vm._v("立减")]), _vm._v(" ”标签商品，满足活动数量，下单"), _c('span', {
    staticClass: "red"
  }, [_vm._v("立减")])])]), _c('div', {
    staticClass: "activity-description-content-item"
  }, [_c('div', {
    staticClass: "item-s"
  }, [_vm._v(" 3.支付立减 ")]), _c('div', {
    staticClass: "item-x"
  }, [_vm._v(" 电商提交订单，线上支付成功。"), _c('span', {
    staticClass: "red"
  }, [_vm._v("订单立减2‰")])])]), _c('div', {
    staticClass: "activity-description-content-item"
  }, [_c('div', {
    staticClass: "item-s"
  }, [_vm._v(" 4.托运立减 ")]), _c('div', {
    staticClass: "item-x"
  }, [_vm._v(" 下单满￥299包邮，满￥500再"), _c('span', {
    staticClass: "red"
  }, [_vm._v("立减1%")])])]), _c('div', {
    staticClass: "activity-description-content-item"
  }, [_c('div', {
    staticClass: "item-s"
  }, [_vm._v(" 5.凑单返利 ")]), _c('div', {
    staticClass: "item-x xia"
  }, [_vm._v(" 单笔订单满￥299，享整订单"), _c('span', {
    staticClass: "red"
  }, [_vm._v("返利3%-7%")])]), _c('div', {
    staticClass: "item-x"
  }, [_vm._v(" 选购“ "), _c('span', {
    staticClass: "h it3"
  }, [_vm._v("返利")]), _vm._v(" ”标签商品，满足活动数量，下单"), _c('span', {
    staticClass: "red"
  }, [_vm._v("立返红包")])])])])]);
}];
export { render, staticRenderFns };